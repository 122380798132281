import React from "react";

import { Block, GetStartedCtas } from "../index";

import Bubble01 from "../../images/get-started-block/background/avatar-bubble-01.svg";
import Bubble02 from "../../images/get-started-block/background/avatar-bubble-02.svg";
import Bubble03 from "../../images/get-started-block/background/avatar-bubble-03.svg";
import Bubble04 from "../../images/get-started-block/background/avatar-bubble-04.svg";
import Bubble05 from "../../images/get-started-block/background/avatar-bubble-05.svg";
import Bubble06 from "../../images/get-started-block/background/avatar-bubble-06.svg";
import Bubble07 from "../../images/get-started-block/background/avatar-bubble-07.svg";
import Bubble08 from "../../images/get-started-block/background/avatar-bubble-08.svg";
import Bubble09 from "../../images/get-started-block/background/avatar-bubble-09.svg";
import Bubble10 from "../../images/get-started-block/background/avatar-bubble-10.svg";

import "./get-started-block.scss";

const GetStartedBlock = () => {
  return (
    <Block centered className="get-started-block" lazy>
      <div className="get-started-block__background" aria-hidden>
        <img
          src={Bubble01}
          className="background-bubble background-bubble--01"
          alt="background-image"
          aria-hidden
          loading="lazy"
        />
        <img
          src={Bubble02}
          className="background-bubble background-bubble--02"
          alt="background-image"
          aria-hidden
          loading="lazy"
        />
        <img
          src={Bubble03}
          className="background-bubble background-bubble--03"
          alt="background-image"
          aria-hidden
          loading="lazy"
        />
        <img
          src={Bubble04}
          className="background-bubble background-bubble--04"
          alt="background-image"
          aria-hidden
          loading="lazy"
        />
        <img
          src={Bubble05}
          className="background-bubble background-bubble--05"
          alt="background-image"
          aria-hidden
          loading="lazy"
        />
        <img
          src={Bubble06}
          className="background-bubble background-bubble--06"
          alt="background-image"
          aria-hidden
          loading="lazy"
        />
        <img
          src={Bubble07}
          className="background-bubble background-bubble--07"
          alt="background-image"
          aria-hidden
          loading="lazy"
        />
        <img
          src={Bubble08}
          className="background-bubble background-bubble--08"
          alt="background-image"
          aria-hidden
          loading="lazy"
        />
        <img
          src={Bubble09}
          className="background-bubble background-bubble--09"
          alt="background-image"
          aria-hidden
          loading="lazy"
        />
        <img
          src={Bubble10}
          className="background-bubble background-bubble--10"
          alt="background-image"
          aria-hidden
          loading="lazy"
        />
      </div>

      <img
        src={Bubble02}
        className="background-bubble background-bubble--mobile"
        alt="background-image"
        aria-hidden
        loading="lazy"
      />
      <h2 className="partie-block__title">Get Started</h2>
      <p className="partie-block__description">
        Partie is available for free on Desktop, iOS & Android devices.
      </p>
      <div className="partie-block__cta">
        <GetStartedCtas />
      </div>
    </Block>
  );
};

export default GetStartedBlock;
